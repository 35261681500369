import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, Card, CardBody, Tooltip,
} from 'shards-react';
import '../../Portal.css';
import RingLoader from 'react-spinners/RingLoader';
import {
  faGavel, faRss, faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { Col, Container, Row } from 'react-grid-system';
import PunishModal from '../../../../components/PunishModal/PunishModal';
import './PlayerView.css';

const Rank = ({ rankname }) => {
  switch (rankname) {
    case 'TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#9654c0', color: '#fdfdfd' }}><span>TESTER</span></div>;
    case 'SR_TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#bf8be1', color: 'black' }}><span>SR TESTER</span></div>;
    case 'BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#59dbc9', color: 'black' }}><span>BUILDER</span></div>;
    case 'SR_BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#a5fff2', color: 'black' }}><span>SR BUILDER</span></div>;
    case 'IRON_VIP':
      return <div className="rank-plate" style={{ backgroundColorr: '#9daec7', color: 'black' }}><span>VIP</span></div>;
    case 'GOLD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#8f826f', color: '#fdfdfd' }}><span>VIP</span></div>;
    case 'DIAMOND_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#7fb3bb', color: 'black' }}><span>VIP</span></div>;
    case 'EMERALD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#5acea3', color: 'black' }}><span>VIP</span></div>;
    case 'ETERNITY_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#b5a5ec', color: 'black' }}><span>VIP</span></div>;
    case 'STREAMER':
      return <div className="rank-plate" style={{ backgroundColor: '#8b32a8', color: '#fdfdfd' }}><span>STREAMER</span></div>;
    case 'YOUTUBER':
      return <div className="rank-plate" style={{ backgroundColor: '#ab001f', color: '#fdfdfd' }}><span>YOUTUBER</span></div>;
    case 'SUPPORT':
      return <div className="rank-plate" style={{ backgroundColor: '#75eb5b', color: 'black' }}><span>SUPPORT</span></div>;
    case 'MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#e4dc3e', color: 'black' }}><span>MOD</span></div>;
    case 'SR_MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#fffa79', color: 'black' }}><span>SR MOD</span></div>;
    case 'TRIAL_ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#c96b30', color: '#fdfdfd' }}><span>TRIAL ADMIN</span></div>;
    case 'ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#ffa267', color: 'black' }}><span>ADMIN</span></div>;
    case 'ELDER':
      return <div className="rank-plate" style={{ backgroundColor: '#00520a', color: '#fdfdfd' }}><span>ELDER</span></div>;
    case 'JR_DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#811313', color: '#fdfdfd' }}><span>JR DEV</span></div>;
    case 'DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#b42424', color: '#fdfdfd' }}><span>DEV</span></div>;
    case 'MANAGER':
      return <div className="rank-plate" style={{ backgroundColor: '#1a5292', color: '#fdfdfd' }}><span>MANAGER</span></div>;
    case 'FOUNDER':
      return <div className="rank-plate" style={{ backgroundColor: '#558dcc', color: '#fdfdfd' }}><span>FOUNDER</span></div>;
    default:
      return <div className="rank-plate" style={{ backgroundColor: '#fdfdfd', color: 'black' }}><span>MEMBER</span></div>;
  }
};

const getViolation = (c) => {
  switch (c) {
    case 'HA':
      return {
        name: 'Harassment',
        ref: '1.1.2',
        code: 'HA',
        desc: 'Showing inappropriate behavior of any sort with the intention to demean or hurt another person.',
        penalty: 6,
      };
    case 'SP':
      return {
        name: 'Spamming',
        ref: '1.1.1',
        code: 'SP',
        desc: 'Cluttering chat with symbols, alpha-or-numeric characters, all-capital letters, words or phrases with the intention to disrupt player experience.',
        penalty: 1,
      };
    case 'VU':
      return {
        name: 'Vulgarity',
        ref: '1.1.3',
        code: 'VU',
        desc: 'Displaying an inappropriate name, skin or cape, or excessive use of inappropriate language that does not belong in chat.',
        penalty: 8,
      };
    case 'RPI':
      return {
        name: 'Releasing Personal Information',
        ref: '1.1.4',
        code: 'RPI',
        desc: 'Releasing information that can identify a person in real life.',
        penalty: 25,
      };
    case 'CA':
      return {
        name: 'Community Advertising',
        ref: '1.1.5',
        code: 'CA',
        desc: "Advertising / publicly posting about another community or unrelated content in Tarvos' chats for personal gain or out of Tarvos' interest.",
        penalty: 25,
      };
    case 'MC':
      return {
        name: 'Malicious Content',
        ref: '1.1.6',
        code: 'MC',
        desc: 'Repetitive trolling, providing false community information or sharing content that includes (suggestive) links to websites or applications not endorsed by Tarvos.',
        penalty: 12,
      };
    case 'FL':
      return {
        name: 'Foreign Language',
        ref: '1.1.7',
        code: 'FL',
        desc: 'Writing in any language other than English. English is the only language supported on Tarvos.',
        penalty: 2,
      };
    case 'MG':
      return {
        name: 'Metagaming',
        ref: '1.1.8',
        code: 'MG',
        desc: "Sharing another player's (or their clan/base) location in public chats or private messages to random players in-game, on Discord or the forums. This includes using knowledge and information gained through these practices to engage in combat.",
        penalty: 8,
      };
    case 'EF':
      return {
        name: 'Ethical Force',
        ref: '1.1.9',
        code: 'EF',
        desc: 'Accusing a player of breaking a community rule, this includes offense or staff related accusations.',
        penalty: 8,
      };
    case 'CL':
      return {
        name: 'Combat Logging',
        ref: '1.2.1',
        code: 'CL',
        desc: 'Forcefully disconnecting from the server during active combat and pursuit to avoid interaction with another player.',
        penalty: 36,
      };
    case 'SS':
      return {
        name: 'Stream Sniping',
        ref: '1.2.2',
        code: 'SS',
        desc: 'Using a livestream from another player to track their location to engage in combat.',
        penalty: 45,
      };
    case 'PBT':
      return {
        name: 'Portal / Bed Trapping',
        ref: '1.2.3',
        code: 'PBT',
        desc: 'Misusing the placement of objects to trap another player in a bed or portal (to weaken them in combat).',
        penalty: 45,
      };
    case 'MLA':
      return {
        name: 'Malicious Landscape Altering',
        ref: '1.3.1',
        code: 'MLA',
        desc: 'Altering a landscape, structure with the intention to ruin other players their experience. A build or manipulation to the landscape that may be deemed malicious.',
        penalty: 40,
      };
    case 'NG':
      return {
        name: 'NSFW Graphics',
        ref: '1.3.2',
        code: 'NG',
        desc: 'Displaying a symbol, icon or graphic on a shield or banner that may be deemed inappropriate or offensive.',
        penalty: 40,
      };
    case 'IB':
      return {
        name: 'Invulnerable Base',
        ref: '1.3.3',
        code: 'IB',
        desc: 'Finding a way to establish a base in a way that base property is unreachable. Clan Totems, chests, furnaces and armor stands are required to be accessible without having to remove any blocks.',
        penalty: 36,
      };
    case 'AB':
      return {
        name: 'Alliance Betrayal',
        ref: '1.4.1',
        code: 'AB',
        desc: 'Starting an alliance with another clan with the intention to betray and grief the clan.',
        penalty: 6,
      };
    case 'RI':
      return {
        name: 'Random Inviting',
        ref: '1.4.2',
        code: 'RI',
        desc: 'Sending out multiple invites using the clan invite command to random people who are not aware of your clan.',
        penalty: 5,
      };
    case 'CH':
      return {
        name: 'Clan Hopping',
        ref: '1.4.3',
        code: 'CH',
        desc: 'Switching between clans to avoid negative effects and consequences. (E.g. from the Karma system.)',
        penalty: 4,
      };
    case 'SD':
      return {
        name: 'Server Disruption',
        ref: '1.5.1',
        code: 'SD',
        desc: "Taking an action that disrupts the operation of Tarvos' its servers, this includes statements, threats or accessory & involvement to the violation.",
        penalty: 86,
      };
    case 'PS':
      return {
        name: 'Prohibited Software',
        ref: '1.5.2',
        code: 'PS',
        desc: 'Using a disallowed client or macro/bind that offers you an advantage over other players.',
        penalty: 66,
      };
    case 'BE':
      return {
        name: 'Bug Exploiting',
        ref: '1.5.3',
        code: 'BE',
        desc: 'Intentionally exploiting a server script or Minecraft bug.',
        penalty: 36,
      };
    case 'PC':
      return {
        name: 'Punish Circumvention',
        ref: '1.5.4',
        code: 'PC',
        desc: 'The act of using an alternative account to circumvent punishments such as mutes and bans.',
        penalty: 86,
      };
    case 'NOS':
      return {
        name: 'Not-Otherwise-Specified',
        ref: '1.5.5',
        code: 'NOS',
        desc: 'You are no longer welcome on Tarvos',
        penalty: 86,
      };
    default:
      return null;
  }
};

const getStandingFormatted = (standing) => {
  if (standing >= 0 && standing <= 5) {
    return (
      <b className="community-standing-badge very-good">
        {standing}
        {' '}
        - Very Good
      </b>
    );
  } if (standing >= 6 && standing <= 18) {
    return (
      <b className="community-standing-badge good">
        {standing}
        {' '}
        - Good
      </b>
    );
  } if (standing >= 19 && standing <= 45) {
    return (
      <b className="community-standing-badge moderate">
        {standing}
        {' '}
        - Moderate
      </b>
    );
  } if (standing >= 45 && standing <= 75) {
    return (
      <b className="community-standing-badge bad">
        {standing}
        {' '}
        - Bad
      </b>
    );
  }
  return (
    <b className="community-standing-badge very-bad">
      {standing}
      {' '}
      - Very Bad
    </b>
  );
};

const PlayerView = ({ superStaff }) => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const [query, setQuery] = useState('');
  const [showPunish, setShowPunish] = useState(false);
  const [showOnlineRealm, setShowOnlineRealm] = useState(false);
  const params = useParams();

  const GetData = () => {
    axios.get(`https://api.tarvos-gaming.com/api/v4/public/staff/players/${params.id}`, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setPlayer(res.data);
      setLoading(false);
    });
  };

  const refreshPlayer = () => {
    GetData();
  };

  useEffect(() => {
    GetData();
  }, []);

  const toggleOnlineRealm = () => {
    setShowOnlineRealm(!showOnlineRealm);
  };

  if (loading) {
    return (
      <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Player</h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col lg={4} xl={4}>
          <h3 className="skeleton-line" style={{ width: "75%" }}></h3>
          <div className="skeleton-line" style={{ width: "25%" }}></div>
          <div style={{
            position: 'relative', width: '80px', height: 'fit-content', marginTop: '1em',
          }}>
            <div className="skeleton-avatar"></div>
          </div>
          <div style={{ marginTop: '1em' }}>
            <div className="skeleton-line" style={{ width: "50%" }}></div>
            <div className="skeleton-line" style={{ width: "50%" }}></div>
            <div className="skeleton-line" style={{ width: "50%" }}></div>
            <div className="skeleton-line" style={{ width: "50%" }}></div>
            <div className="skeleton-line" style={{ width: "50%" }}></div>
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="skeleton-line" style={{ padding: ".75rem 1.25rem", height: "2.75em", width: "100%" }}></div>
          </div>
          <hr />
        </Col>
      </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Player</h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col lg={4} xl={4}>
          <h3 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{(player.prefs.is_nicked) ? (`${player.prefs.nickname} (${player.name})`) : player.name}</h3>
          <Rank rankname={player.player_rank} />
          <div style={{
            position: 'relative', width: '80px', height: 'fit-content', marginTop: '1em',
          }}
          >
            {
                                (player.online.status) && (
                                <>
                                  <div
                                    id="player-status"
                                    style={{
                                      position: 'absolute', top: '-0.5em', right: '-0.5em', width: '1em', height: '1em', backgroundColor: '#00AA00', borderRadius: '1em', color: 'white',
                                    }}
                                  />
                                  <Tooltip open={showOnlineRealm} toggle={toggleOnlineRealm} placement="right" target="#player-status">
                                    <b>{player.online.realm}</b>
                                  </Tooltip>
                                </>
                                )
                            }
            <img src={`https://crafatar.com/avatars/${player.uuid}?size=80`} />
          </div>
          <div style={{ marginTop: '1em' }}>
            <div>
              <b>Balance:</b>
              {' '}
              {player.balance}
              Ⓣ
            </div>
            <div>
              <b>Gems:</b>
              {' '}
              {player.gems}
            </div>
            <div>
              <b>Last Online:</b>
              {' '}
              {moment(player.last_online_ts).fromNow()}
            </div>
            <div>
              <b>Community Standing:</b>
              {' '}
              {getStandingFormatted(player.punish_stats.punish_standing)}
            </div>
            <div>
              <b>Watchlisted:</b>
              {' '}
              {(player.watchlist) ? 'Yes' : 'No'}
            </div>
            {
              (superStaff) && <>
                <div>
                  <b>IP Address:</b>
                  {' '}
                  {player.ip_information.ip_address}
                </div>
                <div>
                  <b>Location:</b>
                  {' '}
                  {player.ip_information.region+', '+player.ip_information.country}
                </div>
              </>
            }
          </div>
          <hr />
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup>
              <Button onClick={() => setShowPunish(true)}>
                <FontAwesomeIcon icon={faGavel} />
                {' '}
                Punish
              </Button>
              <Button>
                <FontAwesomeIcon icon={faRss} />
                {' '}
                Logs
              </Button>
              <Button>
                <FontAwesomeIcon icon={faUserSecret} />
                {' '}
                Watchlist
              </Button>
            </ButtonGroup>
          </div>
          <hr />
        </Col>
        <Col lg={8} xl={8}>
          <Row style={{ rowGap: '1em' }}>
            <Col xs={12}>
              <h4>Punish History</h4>
              <Row style={{ rowGap: '1em' }}>
                {
                                (player.punishments.length > 0)
                                  ? player.punishments.map((p) => {
                                    const v = getViolation(p.violation_code);
                                    return (
                                      <Col xs={12}>
                                        <Card>
                                          <CardBody>
                                            <h5 style={{ marginBottom: '0.1em' }}>
                                              #
                                              {p.punish_name}
                                            </h5>
                                            <div style={{ marginBottom: '0.675em' }}>
                                              {moment(p.issued_ts).fromNow()}
                                              {' '}
                                              -
                                              {' '}
                                              {(p.permanent) ? 'Permanent' : (moment().isSameOrAfter(moment(p.issued_ts).add(p.duration))) ? 'Expired' : 'In Force'}
                                            </div>
                                            <div>
                                              <b>
                                                {v.ref}
                                                {' '}
                                                -
                                                {' '}
                                                {v.name}
                                              </b>
                                              <br />
                                              {v.desc}
                                            </div>
                                            <div>
                                              Penalty:
                                              {v.penalty}
                                            </div>
                                            <div>
                                              Action:
                                              {p.punish_type}
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    );
                                  }) : (
                                    <Col xs={12}>
                                      Squeaky clean!
                                      {' '}
                                      {player.name}
                                      {' '}
                                      has no punish history.
                                    </Col>
                                  )
                            }
              </Row>
            </Col>
            <Col xs={12} />
          </Row>
        </Col>
      </Row>
      <PunishModal
        superStaff={superStaff}
        player={player}
        open={showPunish}
        toggle={() => setShowPunish(!showPunish)}
        callback={refreshPlayer}
      />
    </Container>
  );
};

export default PlayerView;
