import React, { useEffect, useState } from 'react';
import {
  faHouseUser, faCog, faClipboardList, faUsers, faUserShield, faServer,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'shards-react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import logo from '../../logo.png';

import './Navbar.css';

const TarvosNavbar = ({ staff, superStaff }) => {
  const location = useLocation();
  const history = useHistory();
  const [players, setPlayers] = useState(0);
  useEffect(() => {
    axios.get('https://api.tarvos-gaming.com/api/v4/public/status').then((res) => {
      setPlayers(res.data.online_players);
    });
  }, []);

  return (
    <Navbar className="navbarmain" expand="md">
      <NavbarBrand href="#"><img alt="tarvos logo" className="navbarimg" src={logo} /></NavbarBrand>
      <Nav className="navbaritems" navbar>
        {
          (location.pathname.startsWith('/staff') && staff) ? (
            <>
              <NavItem>
                <NavLink onClick={() => history.push('/staff/players')} active={location.pathname === '/staff/players'}>
                  <FontAwesomeIcon icon={faUsers} />
                  {' '}
                  Players
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => history.push('/staff/logs')} active={location.pathname === '/staff/logs'}>
                  <FontAwesomeIcon icon={faClipboardList} />
                  {' '}
                  Logs
                </NavLink>
              </NavItem>
              {
            (superStaff) && (
            <NavItem>
              <NavLink onClick={() => history.push('/staff/servers')} active={location.pathname === '/staff/servers'}>
                <FontAwesomeIcon icon={faServer} />
                {' '}
                Servers
              </NavLink>
            </NavItem>
            )
            }
            </>
          ) : (
            <>
              <NavItem>
                <NavLink onClick={() => history.push('/players')} active={location.pathname === '/players'}>
                  <FontAwesomeIcon icon={faHouseUser} />
                  {' '}
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => history.push('/prefs')} active={location.pathname === '/prefs'}>
                  <FontAwesomeIcon icon={faCog} />
                  {' '}
                  Prefs
                </NavLink>
              </NavItem>
              {
            (staff) && (
            <NavItem>
              <NavLink onClick={() => history.push('/staff/players')} active={location.pathname === '/staff/players'}>
                <FontAwesomeIcon icon={faUserShield} />
                {' '}
                Staff
              </NavLink>
            </NavItem>
            )
              }
            </>
          )
          }
      </Nav>
      <Nav navbar className="ml-auto">
        <div style={{ marginRight: '1em', display: 'flex', alignItems: 'center' }}>
          {players}
          {' '}
          players online.
        </div>
      </Nav>
    </Navbar>
  );
};

export default TarvosNavbar;
