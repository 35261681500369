import React from 'react';
import {
  faHouseUser, faCog, faClipboardList, faUsers, faUserShield, faServer,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Nav,
  NavItem,
  NavLink,
} from 'shards-react';
import { useLocation, useHistory } from 'react-router-dom';

import './MobileNav.css';

const MobileNavbar = ({ staff, superStaff }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <Nav className="mobilenav" pills>
      {
                (location.pathname.startsWith('/staff') && staff) ? (
                  <>
                    <NavItem>
                      <NavLink onClick={() => history.push('/staff/players')} active={location.pathname === '/staff/players'}>
                        <FontAwesomeIcon icon={faUsers} />
                        {' '}
                        Players
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink onClick={() => history.push('/staff/logs')} active={location.pathname === '/staff/logs'}>
                        <FontAwesomeIcon icon={faClipboardList} />
                        {' '}
                        Logs
                      </NavLink>
                    </NavItem>
                    {
                        (superStaff) && (
                        <NavItem>
                          <NavLink onClick={() => history.push('/staff/servers')} active={location.pathname === '/staff/servers'}>
                            <FontAwesomeIcon icon={faServer} />
                            {' '}
                            Servers
                          </NavLink>
                        </NavItem>
                        )
                    }
                  </>
                ) : (
                  <>
                    <NavItem>
                      <NavLink onClick={() => history.push('/players')} active={location.pathname === '/players'}>
                        <FontAwesomeIcon icon={faHouseUser} />
                        {' '}
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink onClick={() => history.push('/prefs')} active={location.pathname === '/prefs'}>
                        <FontAwesomeIcon icon={faCog} />
                        {' '}
                        Prefs
                      </NavLink>
                    </NavItem>
                    {
                        (staff) && (
                        <NavItem>
                          <NavLink onClick={() => history.push('/staff/players')} active={location.pathname === '/staff/players'}>
                            <FontAwesomeIcon icon={faUserShield} />
                            {' '}
                            Staff
                          </NavLink>
                        </NavItem>
                        )
                    }
                  </>
                )
            }
    </Nav>
  );
};

export default MobileNavbar;
