import {
  faBan, faCommentSlash, faDoorOpen, faComment, faShieldAlt, faHardHat, faUsers, faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import {
  Col, Container, Row, Hidden, Visible,
} from 'react-grid-system';
import {
  Card, CardBody, Modal, ModalBody, ModalHeader, Button,
} from 'shards-react';
import './PunishModal.css';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import RingLoader from 'react-spinners/RingLoader';

const PunishType = ({
  type, display, icon, ...props
}) => (
  <Card {...props} className="punish-option-btn">
    <CardBody>
      <b>
        {(icon) && <FontAwesomeIcon icon={icon} />}
        {' '}
        {display}
      </b>
    </CardBody>
  </Card>
);

const Categories = ({ setStage }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col>
      <PunishType onClick={() => setStage('comm')} type="comm" icon={faComment} display="Comms" />
    </Col>
    <Col>
      <PunishType onClick={() => setStage('combat')} type="combat" icon={faShieldAlt} display="Combat" />
    </Col>
    <Col>
      <PunishType onClick={() => setStage('build')} type="build" icon={faHardHat} display="Build" />
    </Col>
    <Col>
      <PunishType onClick={() => setStage('clan')} type="clan" icon={faUsers} display="Clan" />
    </Col>
    <Col>
      <PunishType onClick={() => setStage('other')} type="other" icon={faQuestion} display="Other" />
    </Col>
  </Row>
);

const Communication = ({ setCode }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('SP')} display="Spam" />
    </Col>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('HA')} display="Harass" />
    </Col>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('VU')} display="Vulgar" />
    </Col>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('RPI')} display="Doxx" />
    </Col>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('CA')} display="Advertise" />
    </Col>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('MC')} display="Malicious" />
    </Col>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('FL')} display="Foreign Language" />
    </Col>
    <Col xs={6} sm={6} md={4}>
      <PunishType onClick={() => setCode('MG')} display="Meta Game" />
    </Col>
    <Col xs={12} sm={12} md={4}>
      <PunishType onClick={() => setCode('EF')} display="Ethical Force" />
    </Col>
  </Row>
);

const Combat = ({ setCode }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col>
      <PunishType onClick={() => setCode('CL')} display="Combat Log" />
    </Col>
    <Col>
      <PunishType onClick={() => setCode('SS')} display="Stream Snipe" />
    </Col>
    <Col>
      <PunishType onClick={() => setCode('PBT')} display="Portal / Bed Trap" />
    </Col>
  </Row>
);

const Build = ({ setCode }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col>
      <PunishType onClick={() => setCode('MLA')} display="Landscape Alter" />
    </Col>
    <Col>
      <PunishType onClick={() => setCode('NG')} display="NSFW Graphics" />
    </Col>
    <Col>
      <PunishType onClick={() => setCode('IB')} display="Invulnerable Base" />
    </Col>
  </Row>
);

const Clan = ({ setCode }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col>
      <PunishType onClick={() => setCode('AB')} display="Alliance Betrayal" />
    </Col>
    <Col>
      <PunishType onClick={() => setCode('RI')} display="Random Invite" />
    </Col>
    <Col>
      <PunishType onClick={() => setCode('CH')} display="Clan Hop" />
    </Col>
  </Row>
);

const Other = ({ setCode }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col md={4} lg={4}>
      <PunishType onClick={() => setCode('SD')} display="Server Disrupt" />
    </Col>
    <Col md={4} lg={4}>
      <PunishType onClick={() => setCode('PS')} display="Prohibited Software" />
    </Col>
    <Col md={4} lg={4}>
      <PunishType onClick={() => setCode('PC')} display="Punish Circumvent" />
    </Col>
    <Col md={6} lg={6}>
      <PunishType onClick={() => setCode('BE')} display="Bug Exploit" />
    </Col>
    <Col md={6} lg={6}>
      <PunishType onClick={() => setCode('NOS')} display="Other" />
    </Col>
  </Row>
);

const Evidence = ({ setStage, setEvidence }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col xs={12} sm={12} md={6}>
      <PunishType
        onClick={() => {
          setStage('confirm');
          setEvidence({
            type: 'W',
          });
        }}
        display="Witnessed"
      />
    </Col>
    <Col xs={12} sm={12} md={6}>
      <PunishType display="Report" />
    </Col>
    <Col xs={12} sm={12} md={4}>
      <PunishType display="Chat Log" />
    </Col>
    <Col xs={12} sm={12} md={4}>
      <PunishType display="Link" />
    </Col>
    <Col xs={12} sm={12} md={4}>
      <PunishType
        onClick={() => {
          setStage('confirm');
          setEvidence({
            type: 'U',
          });
        }}
        display="Ask Before Unban"
      />
    </Col>
  </Row>
);

const Confirm = ({ code, evidence }) => (
  <Row style={{ rowGap: '1em' }}>
    <Col>
      <div>
        <b>Violation:</b>
        {' '}
        {code}
      </div>
      <div>
        <b>Evidence:</b>
        {' '}
        {evidence.type}
      </div>
    </Col>
  </Row>
);

const PunishModal = ({
  open, toggle, player, superStaff, callback,
}) => {
  const [cookies] = useCookies(['tarvosportal']);
  const [superMode, setSuperMode] = useState(false);
  const [cat, setCat] = useState(null);
  const [stage, setStage] = useState('cat');
  const [code, setCode] = useState(null);
  const [evidence, setEvidence] = useState({
    type: null,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  useEffect(() => {
    setStage('cat');
    setSuperMode(false);
    setCode(null);
    setEvidence({
      type: null,
    });
    setLoading(false);
    setSuccess(null);
  }, [open]);

  const sendPunishment = () => {
    setLoading(true);
    axios.post(`https://api.tarvos-gaming.com/api/v4/public/staff/players/${player.player_id}/punish`,
      {
        v_code: code,
        e_code: evidence.type,
      },
      {
        headers: {
          Authorization: `Bearer ${cookies.tarvosportal}`,
        },
      }).then((res) => {
      setSuccess(true);
      setLoading(false);
      callback();
    }).catch((err) => {
      setSuccess(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (stage !== 'cat' && stage !== 'confirm') {
      setCat(stage);
    }
  }, [stage]);
  return (
    <Modal size="lg" centered open={open} toggle={toggle}>
      <ModalHeader>
        Punish
        {player.name}
        {' '}
        {(code !== null && stage !== 'confirm') ? '| EVIDENCE' : (stage !== 'cat') && (`| ${stage.toUpperCase()}`)}
      </ModalHeader>
      <ModalBody>
        <Container>
          {
                        (loading)
                          ? (
                            <Row>
                              <Col style={{ textAlign: 'center' }}>
                                <div className="spinner">
                                  <RingLoader
                                    size={50}
                                    loading
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (success !== null)
                            ? (
                              <Row>
                                <Col style={{ textAlign: 'center' }}>
                                  <h5>
                                    Punish
                                    {(success) ? 'successful' : 'failed'}
                                  </h5>
                                </Col>
                              </Row>
                            ) : (
                              <>
                                {
                            (superMode) ? (
                              <Row>
                                <Col xs={4}>
                                  <PunishType type="ban" icon={faBan} display="Ban" />
                                </Col>
                                <Col xs={4}>
                                  <PunishType type="kick" icon={faDoorOpen} display="Kick" />
                                </Col>
                                <Col xs={4}>
                                  <PunishType type="mute" icon={faCommentSlash} display="Mute" />
                                </Col>
                              </Row>
                            ) : (
                              (code !== null && stage !== 'confirm') ? <Evidence setStage={setStage} setEvidence={setEvidence} /> : (stage === 'cat')
                                ? <Categories setStage={setStage} /> : (stage === 'comm')
                                  ? <Communication setCode={setCode} /> : (stage === 'combat')
                                    ? <Combat setCode={setCode} /> : (stage === 'build')
                                      ? <Build setCode={setCode} /> : (stage === 'clan')
                                        ? <Clan setCode={setCode} /> : (stage === 'other')
                                          ? <Other setCode={setCode} /> : (stage === 'confirm')
                                            ? <Confirm code={code} evidence={evidence} /> : <></>
                            )
                        }
                                { (success == null) && (
                                <Row>
                                  <Col>
                                    <hr />
                                    <Hidden xs sm>
                                      {(stage !== 'cat') && <Button onClick={() => ((code === null || stage === 'confirm') ? setStage('cat') : setCode(null))}>Back</Button>}
                                      {(superStaff && stage === 'cat') && <Button theme="warning" onClick={() => setSuperMode(true)}>Super Mode</Button>}
                                      {(stage == 'confirm') && <Button onClick={() => sendPunishment()} theme="success">Confirm</Button>}
                                    </Hidden>
                                    <Visible xs sm>
                                      {(stage !== 'cat') && <Button block onClick={() => ((stage === 'confirm') ? setStage(cat) : (code === null) ? setStage('cat') : setCode(null))}>Back</Button>}
                                      {(superStaff && stage === 'cat') && <Button theme="warning" block onClick={() => setSuperMode(true)}>Super Mode</Button>}
                                      {(stage == 'confirm') && <Button onClick={() => sendPunishment()} block theme="success">Confirm</Button>}
                                    </Visible>
                                  </Col>
                                </Row>
                                )}
                              </>
                            )
                    }
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default PunishModal;
