import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Button,
} from 'shards-react';
import '../Portal.css';
import RingLoader from 'react-spinners/RingLoader';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { useCookies } from 'react-cookie';

const AccountPrefs = () => {
  const [cookies, setCookie] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const history = useHistory();

  const GetData = () => {
    axios.get('https://api.tarvos-gaming.com/api/v4/public/me', {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setAccount(res.data);
      setLoading(false);
    });
  };

  const GrantKey = () => {
    axios.post('https://api.tarvos-gaming.com/api/v4/public/me/grant', {}, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then(() => {
      GetData();
    });
  };

  const DeleteAccount = () => {
    axios.delete('https://api.tarvos-gaming.com/api/v4/public/me/AREYOUSUREABOUTDELETINGYOURACCOUNT', {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then(() => {
      setCookie('tarvosportal', '', { path: '/' });
      history.push('/login');
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  if (loading) {
    return (
      <div style={{
        height: '100vh',
      }}
      >
        <div className="center spinner">
          <RingLoader
            size={50}
            loading
          />
        </div>
      </div>
    );
  }
  return (
    <Container>
      <Row>
        <Col style={{ paddingTop: '2em', paddingBottom: '2em' }}>
          <h1>Account Preferences</h1>
          <h4>Info</h4>
          <p>
            Name:
            {' '}
            {account.name}
          </p>
          <p>
            Created:
            {' '}
            {moment(account.created_ts).format('DD/MM/YYYY')}
            {' '}
            -
            {' '}
            {moment(account.created_ts).fromNow()}
          </p>
          <h4>Developer</h4>
          { (account.public_api_key !== '') ? (
            <p>
              API-Key:
              {' '}
              {account.public_api_key}
            </p>
          ) : <Button onClick={() => GrantKey()}>Grant API Key</Button> }
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Delete Account</h4>
          <i>Deleting your account is instant and irrevocable.</i>
          <br />
          <hr />
          <Button theme="danger" onClick={() => DeleteAccount()}>Delete Account</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountPrefs;
