import React, { useEffect, useState } from 'react';
import { Card } from 'shards-react';

import '../Portal.css';
import './Player.css';
import RingLoader from 'react-spinners/RingLoader';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import ReactMCSkinViewer from 'react-minecraft-skin-viewer';
import { useCookies } from 'react-cookie';
import { Container, Row, Col } from 'react-grid-system';
import bg from '../../../bg.jpg';
import { faChartBar, faHatWizard, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PlayerAvatar = ({ player }) => (
  <div className="player-avatar-body">
    <ReactMCSkinViewer
      src={`https://crafatar.com/skins/${player.uuid}`}
      height="270"
    />
    {/* <img src={`https://crafatar.com/renders/body/${player.uuid}`}></img> */}
  </div>
);

const Rank = ({ rankname }) => {
  switch (rankname) {
    case 'TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#9654c0', color: '#fdfdfd' }}><span>TESTER</span></div>;
    case 'SR_TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#bf8be1', color: 'black' }}><span>SR TESTER</span></div>;
    case 'BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#59dbc9', color: 'black' }}><span>BUILDER</span></div>;
    case 'SR_BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#a5fff2', color: 'black' }}><span>SR BUILDER</span></div>;
    case 'IRON_VIP':
      return <div className="rank-plate" style={{ backgroundColorr: '#9daec7', color: 'black' }}><span>VIP</span></div>;
    case 'GOLD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#8f826f', color: '#fdfdfd' }}><span>VIP</span></div>;
    case 'DIAMOND_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#7fb3bb', color: 'black' }}><span>VIP</span></div>;
    case 'EMERALD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#5acea3', color: 'black' }}><span>VIP</span></div>;
    case 'ETERNITY_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#b5a5ec', color: 'black' }}><span>VIP</span></div>;
    case 'STREAMER':
      return <div className="rank-plate" style={{ backgroundColor: '#8b32a8', color: '#fdfdfd' }}><span>STREAMER</span></div>;
    case 'YOUTUBER':
      return <div className="rank-plate" style={{ backgroundColor: '#ab001f', color: '#fdfdfd' }}><span>YOUTUBER</span></div>;
    case 'SUPPORT':
      return <div className="rank-plate" style={{ backgroundColor: '#75eb5b', color: 'black' }}><span>SUPPORT</span></div>;
    case 'MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#e4dc3e', color: 'black' }}><span>MOD</span></div>;
    case 'SR_MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#fffa79', color: 'black' }}><span>SR MOD</span></div>;
    case 'TRIAL_ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#c96b30', color: '#fdfdfd' }}><span>TRIAL ADMIN</span></div>;
    case 'ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#ffa267', color: 'black' }}><span>ADMIN</span></div>;
    case 'ELDER':
      return <div className="rank-plate" style={{ backgroundColor: '#00520a', color: '#fdfdfd' }}><span>ELDER</span></div>;
    case 'JR_DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#811313', color: '#fdfdfd' }}><span>JR DEV</span></div>;
    case 'DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#b42424', color: '#fdfdfd' }}><span>DEV</span></div>;
    case 'MANAGER':
      return <div className="rank-plate" style={{ backgroundColor: '#1a5292', color: '#fdfdfd' }}><span>MANAGER</span></div>;
    case 'FOUNDER':
      return <div className="rank-plate" style={{ backgroundColor: '#558dcc', color: '#fdfdfd' }}><span>FOUNDER</span></div>;
    default:
      return <div className="rank-plate" style={{ backgroundColor: '#fdfdfd', color: 'black' }}><span>MEMBER</span></div>;
  }
};

const PlayerInfo = ({ player }) => (
  <Card className="player-card">
    <Container fluid className="player-info-container">
      <Row>
        <Col align="center" sm={12}>
          <h1>{player.name}</h1>
        </Col>
        <Col align="center" sm={12}>
          <Rank rankname={player.player_rank} />
        </Col>
        <Col align="center" sm={12}>
          <PlayerAvatar player={player} />
        </Col>
        <Col align="center" sm={12}>
          <b>Balance</b>
          <div>
            {player.balance}
            Ⓣ
          </div>
          <b>Gems</b>
          <div>
            {player.gems}
          </div>
          <b>Last Online</b>
          <div>{moment(player.last_online_ts).fromNow()}</div>
        </Col>
      </Row>
    </Container>
  </Card>
);

const PlayerArcadeStats = ({ player }) => (
  <Card className="player-card">
    <Container
      fluid
      className="player-info-container"
      style={{
        maxHeight: '35em',
        overflowY: 'hidden',
      }}
    >
      <Row>
        <h1>Arcade Stats</h1>
      </Row>
      <Row>
        <div style={{
          width: '100%',
          paddingBottom: '3em',
          maxHeight: '30em',
          overflowY: 'scroll',
        }}
        >
          <b>Fireball Spleef</b>
          <div>
            Wins:
            {player.arcade_stats.fireball_spleef_wins}
          </div>
          <div>
            Rounds played:
            {player.arcade_stats.fireball_spleef_played}
          </div>
          <div>
            Fireballs thrown:
            {player.arcade_stats.fireball_spleef_fireballs_thrown}
          </div>
          <b>Death Swap</b>
          <div>
            Wins:
            {player.arcade_stats.death_swap_wins}
          </div>
          <div>
            Rounds played:
            {player.arcade_stats.death_swap_played}
          </div>
          <b>Block Shuffle</b>
          <div>
            Wins:
            {player.arcade_stats.block_shuffle_wins}
          </div>
          <div>
            Rounds played:
            {player.arcade_stats.block_shuffle_played}
          </div>
          <div>
            Blocks found:
            {player.arcade_stats.block_shuffle_blocks_found}
          </div>
          <b>Portal Rush</b>
          <div>
            Wins:
            {player.arcade_stats.portal_rush_wins}
          </div>
          <div>
            Rounds played:
            {player.arcade_stats.portal_rush_played}
          </div>
          <div>
            Best time:
            {player.arcade_stats.portal_rush_best_time}
          </div>
          <b>Volcano Rush</b>
          <div>
            Wins:
            {player.arcade_stats.volcano_escape_wins}
          </div>
          <div>
            Rounds played:
            {player.arcade_stats.volcano_escape_played}
          </div>
          <div>
            Best time:
            {player.arcade_stats.volcano_escape_best_time}
          </div>
          <b>Advancement Race</b>
          <div>
            Wins:
            {player.arcade_stats.advancement_race_wins}
          </div>
          <div>
            Rounds played:
            {player.arcade_stats.advancement_race_played}
          </div>
          <div>
            Best amount:
            {player.arcade_stats.advancement_race_best_amount}
          </div>
          <div>
            Deaths:
            {player.arcade_stats.advancement_race_deaths}
          </div>
          <b>Bed Hunt</b>
          <div>
            Wins:
            {player.arcade_stats.bed_hunt_wins}
          </div>
          <div>
            Rounds played:
            {player.arcade_stats.bed_hunt_played}
          </div>
          <div>
            Beds destroyed:
            {player.arcade_stats.bed_hunt_beds_destroyed}
          </div>
          <div>
            Deaths:
            {player.arcade_stats.bed_hunt_deaths}
          </div>
        </div>
      </Row>
    </Container>
  </Card>
);

const ButtonCard = ({ label, icon, to }) => {
  const history = useHistory()
  return <Card onClick={() => (to != null) && history.push(to)} className="button-card">
    <FontAwesomeIcon icon={icon} />{label}
  </Card>
}

const Player = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const [activeClass, setActiveClass] = useState(null);
  const params = useParams();

  const GetData = () => {
    axios.get(`https://api.tarvos-gaming.com/api/v4/public/me/players/${params.id}`, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setPlayer(res.data.player);
      if ('active_class' in res.data) {
        setActiveClass(res.data.active_class);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  if (loading) {
    return (
      <div style={{
        height: '100vh',
      }}
      >
      <div style={{
        backgroundImage: `url(${bg})`,
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: -1,
      }}
      />
        <div className="center spinner">
          <RingLoader
            size={50}
            loading
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <div style={{
        backgroundImage: `url(${bg})`,
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: -1,
      }}
      />
      <Container style={{ paddingTop: '1em' }}>
        <Row align="stretch" justify="center">
          <Col md={6} lg={4}>
            <PlayerInfo player={player} />
          </Col>
          <Col md={6} lg={4} style={{ display: "flex", flexDirection: "column", rowGap: "1em" }}>
            <ButtonCard to={`/players/${params.id}/character/`} icon={faHatWizard} label="Character" />
            <ButtonCard to={`/players/${params.id}/clan/`} icon={faShieldAlt} label="Clan" />
            <ButtonCard icon={faChartBar} label="Stats" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Player;
