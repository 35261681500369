import React, { useEffect, useState } from 'react';
import { Card } from 'shards-react';

import '../Portal.css';
import './Clan.css';
import RingLoader from 'react-spinners/RingLoader';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useCookies } from 'react-cookie';
import { Container, Row, Col } from 'react-grid-system';
import bg from '../../../bg.jpg';
import { faArrowLeft, faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

const MemberCard = ({ member, leader }) => {
	return <Card className="member-card">
		<img style={{ maxWidth: '100%', maxHeight: '100%' }} src={`https://crafatar.com/avatars/${member.uuid}?size=40`} /> {member.name}
		{(member.player_id === leader) && <FontAwesomeIcon style={{ marginLeft: '1em' }} icon={faCrown} />}
	</Card>
}

const Clan = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const [clan, setClan] = useState(null);
  const [activeClass, setActiveClass] = useState(null);
  const params = useParams();
  const history = useHistory()

  const GetData = () => {
    axios.get(`https://api.tarvos-gaming.com/api/v4/public/me/players/${params.id}`, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setPlayer(res.data.player);
      if ('active_class' in res.data) {
        setActiveClass(res.data.active_class);
      }
    });
  };

  const GetClanData = () => {
	  console.log(player.character.clan_info.clan_id)
	axios.get(`https://api.tarvos-gaming.com/api/v4/public/clans/${player.character.clan_info.clan_id}`, {
		headers: {
		  Authorization: `Bearer ${cookies.tarvosportal}`,
		},
	}).then((res) => {
		console.log(res)
		if (res.data !== null && res.data.clan !== null) {
			setClan(res.data.clan);
		}
		setLoading(false);
	});
  }

  useEffect(() => {
    GetData();
  }, []);

  useEffect(() => {
	if (player !== null && player.character !== null) {
		GetClanData();
	}
  }, [player])

  if (loading) {
    return (
      <div style={{
        height: '100vh',
      }}
      >
		<div style={{
			backgroundImage: `url(${bg})`,
			position: 'absolute',
			top: 0,
			height: '100%',
			width: '100%',
			zIndex: -1,
		}}
		/>
        <div className="center spinner">
          <RingLoader
            size={50}
            loading
          />
        </div>
      </div>
    );
  }
  return (
    <>
		<div style={{
			backgroundImage: `url(${bg})`,
			position: 'absolute',
			top: 0,
			height: '100%',
			width: '100%',
			zIndex: -1,
		}}
		/>
		<Container style={{ paddingTop: '1em', color: 'white' }}>
			<Row align="stretch" justify="center" style={{ position: "relative" }}>
				<div className='back-button-container'>
					<FontAwesomeIcon onClick={() => history.push(`/players/${params.id}`)} className="back-button" icon={faArrowLeft} />
				</div>
				<Col align="center" sm={12}>
				<h1>{(player.character.clan_info.in_clan) ? player.character.clan_info.clan_name : 'Clan'}</h1>
				<h4>{(clan !== null) && clan.description}</h4>
				</Col>
			</Row>
			<Row align="stretch" justify="center">
				<hr />
			</Row>
			<Row align="stretch" justify="center" style={{ rowGap: '2em' }}>
				{(!player.character.clan_info.in_clan) ? <p>No clan.</p>
				: (
				<>
					<Col xs={12} sm={6}>
						<Card className="clan-card">
							<b>Balance</b>
							<div>{clan.balance}Ⓣ</div>
							<b>Souls</b>
							<div>{clan.souls}</div>
							<b>Members</b>
							<div>{clan.members.length}/{clan.max_members}</div>
						</Card>
					</Col>
					<Col xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', rowGap: '1em', height: '50vh', overflowY: 'scroll' }}>
						{
							clan.members.map(m => <MemberCard leader={clan.leader_id} member={m} />)
						}
					</Col>
				</>
				)}
			</Row>
		</Container>
    </>
  );
};

export default Clan;
