import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';
import './index.css';
import App from './App';
// import * as serviceWorker from './service-worker';

Sentry.init({
  dsn: 'https://b06be8f4edc94fc9bbadc6c844ab61c5@sentry.tarvos-gaming.com/5',
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

const customHistory = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={customHistory}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
