import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, CardTitle, CardSubtitle, FormInput, InputGroup, InputGroupAddon, InputGroupText, Button
} from 'shards-react';
import '../Portal.css';
import './Store.css';
import RingLoader from 'react-spinners/RingLoader';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { Col, Container, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { PayPalButtons } from '@paypal/react-paypal-js';

const Rank = ({ rankname }) => {
  switch (rankname) {
    case 'TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#9654c0', color: '#fdfdfd' }}><span>TESTER</span></div>;
    case 'SR_TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#bf8be1', color: 'black' }}><span>SR TESTER</span></div>;
    case 'BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#59dbc9', color: 'black' }}><span>BUILDER</span></div>;
    case 'SR_BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#a5fff2', color: 'black' }}><span>SR BUILDER</span></div>;
    case 'IRON_VIP':
      return <div className="rank-plate" style={{ backgroundColorr: '#9daec7', color: 'black' }}><span>VIP</span></div>;
    case 'GOLD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#8f826f', color: '#fdfdfd' }}><span>VIP</span></div>;
    case 'DIAMOND_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#7fb3bb', color: 'black' }}><span>VIP</span></div>;
    case 'EMERALD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#5acea3', color: 'black' }}><span>VIP</span></div>;
    case 'ETERNITY_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#b5a5ec', color: 'black' }}><span>VIP</span></div>;
    case 'STREAMER':
      return <div className="rank-plate" style={{ backgroundColor: '#8b32a8', color: '#fdfdfd' }}><span>STREAMER</span></div>;
    case 'YOUTUBER':
      return <div className="rank-plate" style={{ backgroundColor: '#ab001f', color: '#fdfdfd' }}><span>YOUTUBER</span></div>;
    case 'SUPPORT':
      return <div className="rank-plate" style={{ backgroundColor: '#75eb5b', color: 'black' }}><span>SUPPORT</span></div>;
    case 'MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#e4dc3e', color: 'black' }}><span>MOD</span></div>;
    case 'SR_MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#fffa79', color: 'black' }}><span>SR MOD</span></div>;
    case 'TRIAL_ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#c96b30', color: '#fdfdfd' }}><span>TRIAL ADMIN</span></div>;
    case 'ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#ffa267', color: 'black' }}><span>ADMIN</span></div>;
    case 'ELDER':
      return <div className="rank-plate" style={{ backgroundColor: '#00520a', color: '#fdfdfd' }}><span>ELDER</span></div>;
    case 'JR_DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#811313', color: '#fdfdfd' }}><span>JR DEV</span></div>;
    case 'DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#b42424', color: '#fdfdfd' }}><span>DEV</span></div>;
    case 'MANAGER':
      return <div className="rank-plate" style={{ backgroundColor: '#1a5292', color: '#fdfdfd' }}><span>MANAGER</span></div>;
    case 'FOUNDER':
      return <div className="rank-plate" style={{ backgroundColor: '#558dcc', color: '#fdfdfd' }}><span>FOUNDER</span></div>;
    default:
      return <div className="rank-plate" style={{ backgroundColor: '#fdfdfd', color: 'black' }}><span>MEMBER</span></div>;
  }
};

const SkeletonCard = () => {
  return (
    <Col lg={4} xl={4}>
      <Card style={{ marginBottom: '1em' }}>
        <CardBody>
          <Row className="skeleton-card" align="center" justify="center">
            <Col align="center" md={4}>
              <div style={{ width: 'fit-content', position: 'relative' }}>
                <div className="skeleton-card-avatar"></div>
              </div>
            </Col>
            <Col md={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h5 className="skeleton-card-line"></h5>
              <div className="skeleton-card-line"></div>
              <div className="skeleton-card-line"></div>
              <div className="skeleton-card-line"></div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

const Store = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const history = useHistory();

  if (loading) {
    return (
      <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Store</h2>
          <hr />
        </Col>
      </Row>
      <Row>
        {
          [...Array(4)].map(() => 
            <SkeletonCard />
          )
        }
      </Row>
    </Container>
    );
  }
  return (
    <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Store</h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginBottom: '1em' }}>
            <CardBody>
              <CardTitle>525 Gems</CardTitle>
              <CardSubtitle>💎</CardSubtitle>
              <hr />
              <Button onClick={()=> history.push('/store/525')}>Buy now $4.99</Button>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginBottom: '1em' }}>
            <CardBody>
              <CardTitle>1070 Gems</CardTitle>
              <CardSubtitle>💎💎</CardSubtitle>
              <hr />
              <Button onClick={()=> history.push('/store/1070')}>Buy now $9.99</Button>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginBottom: '1em' }}>
            <CardBody>
              <CardTitle>2140 Gems</CardTitle>
              <CardSubtitle>💎💎💎</CardSubtitle>
              <hr />
              <Button onClick={()=> history.push('/store/2140')}>Buy now $19.99</Button>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{ marginBottom: '1em' }}>
            <CardBody>
              <CardTitle>5500 Gems</CardTitle>
              <CardSubtitle>💎💎💎💎</CardSubtitle>
              <hr />
              <Button onClick={()=> history.push('/store/5500')}>Buy now $49.99</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
        <h4>About Gems</h4>
        <p>What are gems? <br />
        Gems are an in-game currency that allows players to purchase cosmetic items and property. <br /> 
        While some may opt to purchase gems, players can accrue Gems as a reward for playing on Tarvos!</p>
        <h5>Can I send other players gems?</h5>
        <p>No. You cannot send other players gems.</p>
        <h5>Why can't I transfer gems?</h5>
        <p>It's to ensure that players don't exchange gems for in-game items.</p>
        <h5>Well I could just sell my account with its gems. Right?</h5>
        <p>Selling your account is against the Minecraft ToS, and along with ours. <br /> Your balance may be revoked if you are caught.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Store;
