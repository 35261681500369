import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, CardTitle, CardSubtitle, FormInput, InputGroup, InputGroupAddon, InputGroupText, Button, FormSelect
} from 'shards-react';
import '../../Portal.css';
import './Checkout.css';
import RingLoader from 'react-spinners/RingLoader';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { Col, Container, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { PayPalButtons } from '@paypal/react-paypal-js';

const Checkout = () => {
    const [cookies] = useCookies(['tarvosportal']);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    const { item } = useParams();
    const history = useHistory();
    const [players, setPlayers] = useState([])
    const [success, setSuccess] = useState(false)

    const [selectedPid, setSelectedPid] = useState(null)

    const isValidItem = (item_id) => {
        return ['525', '1070', '2140', '5500'].includes(item_id)
    }

    if (!isValidItem(item)) {
        history.goBack()
    }

    useEffect(() => {
        axios.get(`https://api.tarvos-gaming.com/api/v4/public/me`, {
          headers: {
            Authorization: `Bearer ${cookies.tarvosportal}`,
          },
        }).then((res) => {
          setPlayers(res.data.players)
          if (res.data.players.length > 0) {
            setSelectedPid(res.data.players[0].player_id)
          }
          setLoading(false)
        });
    }, [])

    return (
    <Container>
        <Row style={{ marginTop: '2em' }}>
            <Col>
            <h2>Checkout</h2>
            <hr />
            </Col>
        </Row>
        {
            (loading) ? <div className="center spinner">
            <RingLoader
              size={50}
              loading
            />
          </div> : (error) ? <><h3>Looks like there was an error with your order.</h3><b>Contact us for support.</b></> : 
          (success) ? <><h3>Thank you.</h3><b>Your order was successful. Enjoy your gems!</b></> :
        <Row>
            <Col sm={12} md={4}>
                <h3>Purchasing {item} Gems</h3>
                <b>Receiving Player</b>
                <FormSelect style={{marginBottom: "1rem" }} onChange={(e) => setSelectedPid(e.target.value)}>
                    {
                        players.map(p => <option key={"opt-"+p.player_id} value={p.player_id}>{p.name}</option>)
                    }
                </FormSelect>
                {
                    (players.length > 0) ?
                    <PayPalButtons
                        createOrder={(data, actions) => {
                            return axios.post(`https://api.tarvos-gaming.com/api/v4/public/store/create-order/${item}/${selectedPid}`, {},
                            {
                            headers: {
                                Authorization: `Bearer ${cookies.tarvosportal}`,
                            }
                            }).then((res) => {
                                return res.data.id
                            })
                        }}
                        onApprove={(data, actions) => {
                            return axios.post(`https://api.tarvos-gaming.com/api/v4/public/store/execute-order/${data.orderID}`, {},
                            {
                            headers: {
                                Authorization: `Bearer ${cookies.tarvosportal}`,
                            }
                            }).then((res) => {
                                if (res.data.status === "COMPLETED") {
                                    setSuccess(true)
                                    return
                                }
                                setError(true)
                            })
                        }}
                        onError={() => {
                            setError(true)
                        }}
                    /> : <b>You don't have any players!</b>
                }
            </Col>
        </Row>
        }
    </Container>
    );
};

export default Checkout;
