import React from 'react';

import {
  Col,
  Row,
} from 'shards-react';

import './LogViewer.css';

class LogViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.logViewRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { loading } = this.props;
      if (!loading) {
        this.scrollToBottom();
      }
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      this.logViewRef.current.scrollTop = this.logViewRef.current.scrollHeight;
    }, 100);
  }

  render() {
    const { loading, logs } = this.props;
    return (
      <div className="logviewer" ref={this.logViewRef}>
        <Col>
          {
                        (loading) ? <></> : logs.map((e) => (
                          <Row className="logline">
                            {e.player_name}
                            :
                            {' '}
                            {e.content}
                          </Row>
                        ))
                    }
        </Col>
      </div>
    );
  }
}

export default LogViewer;
