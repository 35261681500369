import React, { useEffect } from 'react';
import '../Portal.css';
import RingLoader from 'react-spinners/RingLoader';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

const Initial = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const history = useHistory();
  const catchTokenError = (response) => {
    if (response.status === 401) {
      history.push('/login');
    } else {
      history.push('/players');
    }
    return response;
  };

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${cookies.tarvosportal}`,
      }),
    };
    fetch('https://api.tarvos-gaming.com/api/v4/public/me', requestOptions).then(catchTokenError);
  }, []);

  return (
    <div className="center spinner">
      <RingLoader
        size={50}
        loading
      />
    </div>
  );
};

export default Initial;
