import React, { useEffect, useRef, useState } from 'react';
import {
  Container, Row, Col, Modal, ModalBody, ModalHeader,
} from 'shards-react';
import '../Portal.css';
import './PlayerPicker.css';
import RingLoader from 'react-spinners/RingLoader';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useCookies } from 'react-cookie';
import bg from '../../../bg.jpg';

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

const Player = ({ onClick, player }) => (
  <div role="button" onClick={onClick} className="player-avatar">
    <img alt={`${player.name}'s avatar`} className="player-avatar-circle" src={`https://crafatar.com/avatars/${player.uuid}`} />
    <h4>{player.name}</h4>
  </div>
);

const PlayerAdd = (props) => (
  <div className="player-avatar" {...props}>
    <div className="player-avatar-circle">
      <span style={{
        fontSize: '80px',
      }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </span>
    </div>
    <h4>Add Player</h4>
  </div>
);

const PlayerPicker = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [players, setPlayers] = useState([]);
  const [name, setName] = useState('');
  const [otp, setOTP] = useState(null);
  const history = useHistory();

  const CheckOTP = () => {
    axios.get(`https://api.tarvos-gaming.com/api/v4/public/me/otp/${otp}`, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      if (!res.data.exists) {
        setOTP(null);
        setModal(false);
        GetData();
      }
    });
  };

  const CreateOTP = () => {
    axios.post('https://api.tarvos-gaming.com/api/v4/public/me/otp', {}, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setOTP(res.data.code);
      setModal(true);
    });
  };

  const GetData = () => {
    axios.get('https://api.tarvos-gaming.com/api/v4/public/me', {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setPlayers(res.data.players);
      setName(res.data.name);
      setLoading(false);
    });
  };

  useInterval(() => CheckOTP(), (otp !== null) ? 2000 : null);

  useEffect(() => {
    GetData();
  }, []);

  if (loading) {
    return (
      <div style={{
        height: '100vh',
      }}
      >
        <div className="center spinner">
          <RingLoader
            size={50}
            loading
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <div style={{
        backgroundImage: `url(${bg})`,
        height: '100vh',
        display: 'flex',
      }}
      >
        <Container className="player-picker-container">
          <Row style={{
            justifyContent: 'center',
          }}
          >
            <h1 style={{
              textAlign: 'center',
            }}
            >
              Hi,
              {' '}
              {name}
              . Welcome back.
            </h1>
          </Row>
          <Row>
            {players.map((p) => <Col><Player onClick={() => history.push(`/players/${p.player_id}`)} player={p} /></Col>)}
            <Col>
              <PlayerAdd onClick={() => CreateOTP()} />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal open={modal} toggle={() => setModal(false)}>
        <ModalHeader>Add Player</ModalHeader>
        <ModalBody>
          <b>OTP Code</b>
          <h3 style={{ marginTop: 15, marginBottom: 15 }}>{otp}</h3>
          🔗 In order to link a player, you need to go in game and verify ownership.
          <br />
          To do so please use the following command.
          <br />
          <code>/verify &lt;code&gt;</code>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PlayerPicker;
