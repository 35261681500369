import React, { useEffect, useState } from 'react';
import { FormSelect } from 'shards-react';
import '../../Portal.css';
import RingLoader from 'react-spinners/RingLoader';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useCookies } from 'react-cookie';
import { Col, Container, Row } from 'react-grid-system';
import LogViewer from '../../../../components/LogViewer/LogViewer';

const Rank = ({ rankname }) => {
  switch (rankname) {
    case 'TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#9654c0', color: '#fdfdfd' }}><span>TESTER</span></div>;
    case 'SR_TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#bf8be1', color: 'black' }}><span>SR TESTER</span></div>;
    case 'BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#59dbc9', color: 'black' }}><span>BUILDER</span></div>;
    case 'SR_BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#a5fff2', color: 'black' }}><span>SR BUILDER</span></div>;
    case 'IRON_VIP':
      return <div className="rank-plate" style={{ backgroundColorr: '#9daec7', color: 'black' }}><span>VIP</span></div>;
    case 'GOLD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#8f826f', color: '#fdfdfd' }}><span>VIP</span></div>;
    case 'DIAMOND_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#7fb3bb', color: 'black' }}><span>VIP</span></div>;
    case 'EMERALD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#5acea3', color: 'black' }}><span>VIP</span></div>;
    case 'ETERNITY_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#b5a5ec', color: 'black' }}><span>VIP</span></div>;
    case 'STREAMER':
      return <div className="rank-plate" style={{ backgroundColor: '#8b32a8', color: '#fdfdfd' }}><span>STREAMER</span></div>;
    case 'YOUTUBER':
      return <div className="rank-plate" style={{ backgroundColor: '#ab001f', color: '#fdfdfd' }}><span>YOUTUBER</span></div>;
    case 'SUPPORT':
      return <div className="rank-plate" style={{ backgroundColor: '#75eb5b', color: 'black' }}><span>SUPPORT</span></div>;
    case 'MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#e4dc3e', color: 'black' }}><span>MOD</span></div>;
    case 'SR_MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#fffa79', color: 'black' }}><span>SR MOD</span></div>;
    case 'TRIAL_ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#c96b30', color: '#fdfdfd' }}><span>TRIAL ADMIN</span></div>;
    case 'ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#ffa267', color: 'black' }}><span>ADMIN</span></div>;
    case 'ELDER':
      return <div className="rank-plate" style={{ backgroundColor: '#00520a', color: '#fdfdfd' }}><span>ELDER</span></div>;
    case 'JR_DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#811313', color: '#fdfdfd' }}><span>JR DEV</span></div>;
    case 'DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#b42424', color: '#fdfdfd' }}><span>DEV</span></div>;
    case 'MANAGER':
      return <div className="rank-plate" style={{ backgroundColor: '#1a5292', color: '#fdfdfd' }}><span>MANAGER</span></div>;
    case 'FOUNDER':
      return <div className="rank-plate" style={{ backgroundColor: '#558dcc', color: '#fdfdfd' }}><span>FOUNDER</span></div>;
    default:
      return <div className="rank-plate" style={{ backgroundColor: '#fdfdfd', color: 'black' }}><span>MEMBER</span></div>;
  }
};

const Logs = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [realm, setRealm] = useState('Hub');
  const [data, setData] = useState(null);
  const params = useParams();

  const GetData = () => {
    setLoading(true);
    axios.get(`https://api.tarvos-gaming.com/api/v4/public/staff/events/${realm}`, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    GetData();
  }, [realm]);
  return (
    <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Logs</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormSelect value={realm} onChange={(e) => { setRealm(e.target.value); }}>
            <option value="Hub">Hub</option>
            <option value="SMP">SMP</option>
            <option value="Arcade">Arcade</option>
          </FormSelect>
          <hr />
          <i>
            {(loading) ? '...' : data.length }
            {' '}
            Messages
          </i>
          <LogViewer loading={loading} logs={data} />
          {(loading) ? (
            <div className="center spinner">
              <RingLoader
                color="#fdfdfd"
                size={50}
                loading
              />
            </div>
          ) : <></>}
        </Col>
      </Row>
    </Container>
  );
};

export default Logs;
