import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, FormInput, InputGroup, InputGroupAddon, InputGroupText,
} from 'shards-react';
import '../../Portal.css';
import './Players.css';
import RingLoader from 'react-spinners/RingLoader';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { Col, Container, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';

const Rank = ({ rankname }) => {
  switch (rankname) {
    case 'TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#9654c0', color: '#fdfdfd' }}><span>TESTER</span></div>;
    case 'SR_TESTER':
      return <div className="rank-plate" style={{ backgroundColor: '#bf8be1', color: 'black' }}><span>SR TESTER</span></div>;
    case 'BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#59dbc9', color: 'black' }}><span>BUILDER</span></div>;
    case 'SR_BUILDER':
      return <div className="rank-plate" style={{ backgroundColor: '#a5fff2', color: 'black' }}><span>SR BUILDER</span></div>;
    case 'IRON_VIP':
      return <div className="rank-plate" style={{ backgroundColorr: '#9daec7', color: 'black' }}><span>VIP</span></div>;
    case 'GOLD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#8f826f', color: '#fdfdfd' }}><span>VIP</span></div>;
    case 'DIAMOND_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#7fb3bb', color: 'black' }}><span>VIP</span></div>;
    case 'EMERALD_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#5acea3', color: 'black' }}><span>VIP</span></div>;
    case 'ETERNITY_VIP':
      return <div className="rank-plate" style={{ backgroundColor: '#b5a5ec', color: 'black' }}><span>VIP</span></div>;
    case 'STREAMER':
      return <div className="rank-plate" style={{ backgroundColor: '#8b32a8', color: '#fdfdfd' }}><span>STREAMER</span></div>;
    case 'YOUTUBER':
      return <div className="rank-plate" style={{ backgroundColor: '#ab001f', color: '#fdfdfd' }}><span>YOUTUBER</span></div>;
    case 'SUPPORT':
      return <div className="rank-plate" style={{ backgroundColor: '#75eb5b', color: 'black' }}><span>SUPPORT</span></div>;
    case 'MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#e4dc3e', color: 'black' }}><span>MOD</span></div>;
    case 'SR_MOD':
      return <div className="rank-plate" style={{ backgroundColor: '#fffa79', color: 'black' }}><span>SR MOD</span></div>;
    case 'TRIAL_ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#c96b30', color: '#fdfdfd' }}><span>TRIAL ADMIN</span></div>;
    case 'ADMIN':
      return <div className="rank-plate" style={{ backgroundColor: '#ffa267', color: 'black' }}><span>ADMIN</span></div>;
    case 'ELDER':
      return <div className="rank-plate" style={{ backgroundColor: '#00520a', color: '#fdfdfd' }}><span>ELDER</span></div>;
    case 'JR_DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#811313', color: '#fdfdfd' }}><span>JR DEV</span></div>;
    case 'DEV':
      return <div className="rank-plate" style={{ backgroundColor: '#b42424', color: '#fdfdfd' }}><span>DEV</span></div>;
    case 'MANAGER':
      return <div className="rank-plate" style={{ backgroundColor: '#1a5292', color: '#fdfdfd' }}><span>MANAGER</span></div>;
    case 'FOUNDER':
      return <div className="rank-plate" style={{ backgroundColor: '#558dcc', color: '#fdfdfd' }}><span>FOUNDER</span></div>;
    default:
      return <div className="rank-plate" style={{ backgroundColor: '#fdfdfd', color: 'black' }}><span>MEMBER</span></div>;
  }
};

const SkeletonCard = () => {
  return (
    <Col lg={4} xl={4}>
      <Card style={{ marginBottom: '1em' }}>
        <CardBody>
          <Row className="skeleton-card" align="center" justify="center">
            <Col align="center" md={4}>
              <div style={{ width: 'fit-content', position: 'relative' }}>
                <div className="skeleton-card-avatar"></div>
              </div>
            </Col>
            <Col md={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h5 className="skeleton-card-line"></h5>
              <div className="skeleton-card-line"></div>
              <div className="skeleton-card-line"></div>
              <div className="skeleton-card-line"></div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

const Players = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState(null);
  const [query, setQuery] = useState('');
  const params = useParams();
  const history = useHistory();

  const GetData = () => {
    axios.get('https://api.tarvos-gaming.com/api/v4/public/staff/players', {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setPlayers(res.data.sort((a, b) => ((a.online) ? (b.online) ? 0 : -1 : (b.online) ? 1 : 0)));
      setLoading(false);
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  if (loading) {
    return (
      <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Players</h2>
          <hr />
          <InputGroup className="player-search" seamless>
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroupAddon>
            <FormInput />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        {
          [...Array(20)].map(() => 
            <SkeletonCard />
          )
        }
      </Row>
    </Container>
    );
  }
  return (
    <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Players</h2>
          <hr />
          <InputGroup className="player-search" seamless>
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroupAddon>
            <FormInput onChange={(e) => setQuery(e.target.value)} />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        {
                        players.filter((p) => query == '' || (query != '' && p.name.toLowerCase().includes(query.toLowerCase()))).map((p) => (
                          <Col key={p.player_id} lg={4} xl={4}>
                            <Card onClick={() => history.push(`/staff/players/${p.player_id}`)} style={{ marginBottom: '1em', cursor: 'pointer' }}>
                              <CardBody>
                                <Row align="center" justify="center">
                                  <Col align="center" md={4}>
                                    <div style={{ width: 'fit-content', position: 'relative' }}>
                                      <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={`https://crafatar.com/avatars/${p.uuid}?size=80`} />
                                      {(p.online) && (
                                        <div style={{
                                          position: 'absolute', top: '-0.5em', right: '-0.5em', width: '1em', height: '1em', backgroundColor: '#00AA00', borderRadius: '1em', color: 'white',
                                        }}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                  <Col md={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h5 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{p.name}</h5>
                                    <Rank rankname={p.player_rank} />
                                    <div>
                                      <b>Balance:</b>
                                      {' '}
                                      {p.balance}
                                      Ⓣ
                                    </div>
                                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      <b>Last Online:</b>
                                      {' '}
                                      {moment(p.last_online_ts).fromNow()}
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        ))
                    }
      </Row>
    </Container>
  );
};

export default Players;
