import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Card, CardBody,
} from 'shards-react';
import '../../Portal.css';
import RingLoader from 'react-spinners/RingLoader';
import {
  faCommentAlt, faHeartbeat, faQuestion, faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useCookies } from 'react-cookie';
import {
  Col, Container, Hidden, Row, Visible,
} from 'react-grid-system';

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

const ServerStatus = ({ status }) => {
  switch (status) {
    case 'ONLINE':
      return <FontAwesomeIcon icon={faHeartbeat} style={{ color: '#00AA00', marginRight: '0.675em' }} />;
    case 'PENDING_RESTART':
      return <FontAwesomeIcon icon={faHeartbeat} style={{ color: '#FFEF00', marginRight: '0.675em' }} />;
    case 'RESTARTING':
      return <FontAwesomeIcon icon={faHeartbeat} style={{ color: '#FFAA00', marginRight: '0.675em' }} />;
    case 'OFFLINE':
      return <FontAwesomeIcon icon={faHeartbeat} style={{ color: '#AA0000', marginRight: '0.675em' }} />;
    case 'STARTING_UP':
      return <FontAwesomeIcon icon={faHeartbeat} style={{ color: '#AA00AA', marginRight: '0.675em' }} />;
    default:
      return <FontAwesomeIcon icon={faQuestion} />;
  }
};

const ServerCard = ({ server }) => (
  <Card>
    <CardBody>
      <Row>
        <Col xs={6} sm={6} md={4}>
          <h5 style={{ marginBottom: 0 }}>{server.name}</h5>
        </Col>
        <Col xs={6} sm={6} md={4}>
          <Row style={{ rowGap: '0.2em' }}>
            <Col xs={12} sm={12} md={6}>
              <b style={{ textTransform: 'capitalize' }}>
                <ServerStatus status={server.status} />
                {server.status.replace('_', ' ').toLowerCase()}
              </b>
            </Col>
            <Col xs={12} sm={12} md={6}>
              {server.onlinePlayers}
              {' '}
              player(s) online
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Visible xs sm>
        <Row align="center" justify="center" direction="row">
          <Col xs={3}>
            <Button><FontAwesomeIcon icon={faRedoAlt} /></Button>
          </Col>
          <Col xs={3}>
            <Button><FontAwesomeIcon icon={faCommentAlt} /></Button>
          </Col>
        </Row>
      </Visible>
      <Hidden xs sm>
        <div style={{
          width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: '0.675em',
        }}
        >
          <Button><FontAwesomeIcon icon={faRedoAlt} /></Button>
          <Button><FontAwesomeIcon icon={faCommentAlt} /></Button>
        </div>
      </Hidden>
    </CardBody>
  </Card>
);

const ServerManager = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [servers, setServers] = useState([]);
  const params = useParams();
  useInterval(() => refreshServers(), 2000);

  const GetData = () => {
    axios.get('https://api.tarvos-gaming.com/api/v4/public/staff/servers', {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setServers(res.data.sort((a, b) => a.onlinePlayers - b.onlinePlayers));
      setLoading(false);
    });
  };

  const refreshServers = () => {
    GetData();
  };

  useEffect(() => {
    GetData();
  }, []);

  if (loading) {
    return (
      <div style={{
        height: '100vh',
      }}
      >
        <div className="center spinner">
          <RingLoader
            size={50}
            loading
          />
        </div>
      </div>
    );
  }
  return (
    <Container>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <h2>Servers</h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row style={{ rowGap: '1em' }}>
            {
                            servers.map((s) => (
                              <Col xs={12}><ServerCard server={s} /></Col>
                            ))
                        }
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ServerManager;
