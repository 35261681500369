import React, { useEffect, useState } from 'react';
import { Card } from 'shards-react';

import '../Portal.css';
import './Character.css';
import RingLoader from 'react-spinners/RingLoader';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useCookies } from 'react-cookie';
import { Container, Row, Col } from 'react-grid-system';
import bg from '../../../bg.jpg';
import { faArrowLeft, faChartBar, faHatWizard, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

const PlayerCharacterInfo = ({ activeClass, character }) => {
  let desc = '';
  let bgColor = '';
  let color = 'black';
  switch (activeClass.category) {
    case 'Assassin':
      bgColor = '#FF5555';
      desc = 'Strong in stealth and strategy.';
      break;
    case 'Knight':
      bgColor = '#55FFFF';
      desc = 'Strong in combat and excellence.';
      break;
    case 'Mage':
      bgColor = '#FF55FF';
      desc = 'Strong in the Art of Magic.';
      break;
    case 'Hunter':
      color = '#fdfdfd';
      bgColor = '#00AA00';
      desc = 'Strong in ranged combat.';
      break;
  }
  return (
    <div
      className="character-origin-card"
      style={{
        backgroundColor: bgColor,
        color,
      }}
    >
      <Row>
        <Col>
          <h4 style={{
            color: 'black',
          }}
          >
            {activeClass.category}
          </h4>
          <div style={{
            color: 'black',
          }}
          >
            {desc}
          </div>
        </Col>
        <Col>
          <div>
            <span style={{
              color: 'black',
            }}
            >
              <b>Gender: </b>
              {character.character_gender}
            </span>
          </div>
          <div>
            <span style={{
              color: 'black',
            }}
            >
              <b>Soul Fragments: </b>
              {character.soul_fragments}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const PlayerOrigin = ({ origin }) => {
  let text = '';
  let bgColor = '';
  let color = '';
  switch (origin) {
    case 'THETIS':
      color = 'black';
      bgColor = '#FFAA00';
      text = 'A small planet with immense power and limited resources. It is relatively close to Earth.';
      break;
    case 'BOCCION':
      color = 'black';
      bgColor = '#AAAAAA';
      text = 'A highly-sophisticated planet in technology. It is 100 million light years from Earth and visitors from this planet can never return...';
      break;
    case 'HULIA':
      color = 'black';
      bgColor = '#55FF55';
      text = 'A once-powerful planet that ruled over the Medusa Merger. During the Merger Intergalactic War, Hulia was obliterated.';
      break;
    case 'CODORIX':
      color = 'black';
      bgColor = '#FF5555';
      text = 'A hostile planet from the Medusa Merger where war never stops.';
      break;
    case 'SAPRA':
      color = 'black';
      bgColor = '#FFFF55';
      text = 'A planet from the Sunflower Galaxy that holds peace with Earth.';
      break;
    case 'ZADORIA':
      color = 'black';
      bgColor = '#FF55FF';
      text = 'Only a light year away from Earth, Zadoria used to be a travel hub between galaxies before it was destroyed.';
      break;
    case 'EARTH':
      color = '#fdfdfd';
      bgColor = '#00AAAA';
      text = 'The land we all know and love.';
      break;
    default:
      break;
  }
  return (
    <div
      className="character-origin-card"
      style={{
        backgroundColor: bgColor,
        color,
      }}
    >
      <Row>
        <Col>
          <h4 style={{ color }}>{origin}</h4>
          <div style={{ color }}>{text}</div>
        </Col>
        <Col style={{
          display: 'grid',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        >
          <PlayerHead origin={origin} />
        </Col>
      </Row>
    </div>
  );
};

const PlayerHead = ({ origin }) => {
  let url = '';
  switch (origin) {
    case 'BOCCION':
      url = 'http://textures.minecraft.net/texture/cfce059e61850594000ae39778556b7eb0afa928fafd573b0d82314e8bdf49d3';
      break;
    case 'THETIS':
      url = 'http://textures.minecraft.net/texture/39241c1570337b841501a2f52c0cefa8bcd44b82e767fef99e243da1cbf1f040';
      break;
    case 'HULIA':
      url = 'http://textures.minecraft.net/texture/868ec55460c9271ea8fba377f72745a245e915cc3577bf83de228d23650a59a0';
      break;
    case 'CODORIX':
      url = 'http://textures.minecraft.net/texture/cb7ed15eb291c8a70ba6844afa9ec2bf01dcb1ff84fad3e8092e2ec0f93b087a';
      break;
    case 'SAPRA':
      url = 'http://textures.minecraft.net/texture/d66a82ae191083f8178f4250f5e73e4008ab098f0ede1c0ae5f6a7f2613630cf';
      break;
    case 'ZADORIA':
      url = 'http://textures.minecraft.net/texture/8e843ea15f7b432436d2dd84cc2bd02ef2096d20e66dbcd37b35b20153f15c15';
      break;
    case 'EARTH':
      url = 'http://textures.minecraft.net/texture/1289d5b178626ea23d0b0c3d2df5c085e8375056bf685b5ed5bb477fe8472d94';
      break;
    default:
      break;
  }
  // https://codepen.io/FrozenBrain/pen/meKoYr
  return (
    <div className="skin spinning">
      <div className="innerCube">
        <div className="back" style={{ backgroundImage: `url(${url})` }} />
        <div className="right" style={{ backgroundImage: `url(${url})` }} />
        <div className="top" style={{ backgroundImage: `url(${url})` }} />
        <div className="bottom" style={{ backgroundImage: `url(${url})` }} />
        <div className="front" style={{ backgroundImage: `url(${url})` }} />
        <div className="left" style={{ backgroundImage: `url(${url})` }} />
        <div className="hatBack" style={{ backgroundImage: `url(${url})` }} />
        <div className="hatRight" style={{ backgroundImage: `url(${url})` }} />
        <div className="hatTop" style={{ backgroundImage: `url(${url})` }} />
        <div className="hatBottom" style={{ backgroundImage: `url(${url})` }} />
        <div className="hatFront" style={{ backgroundImage: `url(${url})` }} />
        <div className="hatLeft" style={{ backgroundImage: `url(${url})` }} />
      </div>
    </div>
  );
};

const ButtonCard = ({ label, icon }) => {
  return <Card className="button-card">
    <FontAwesomeIcon icon={icon} />{label}
  </Card>
}

const Character = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const [activeClass, setActiveClass] = useState(null);
  const params = useParams();
  const history = useHistory()

  const GetData = () => {
    axios.get(`https://api.tarvos-gaming.com/api/v4/public/me/players/${params.id}`, {
      headers: {
        Authorization: `Bearer ${cookies.tarvosportal}`,
      },
    }).then((res) => {
      setPlayer(res.data.player);
      if ('active_class' in res.data) {
        setActiveClass(res.data.active_class);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    GetData();
  }, []);

  if (loading) {
    return (
      <div style={{
        height: '100vh',
      }}
      >
        <div className="center spinner">
          <RingLoader
            size={50}
            loading
          />
        </div>
      </div>
    );
  }
  return (
    <>
		<div style={{
			backgroundImage: `url(${bg})`,
			position: 'absolute',
			top: 0,
			height: '100%',
			width: '100%',
			zIndex: -1,
		}}
		/>
		<Container style={{ paddingTop: '1em', color: 'white' }}>
			<Row align="stretch" justify="center" style={{ position: "relative" }}>
				<div className='back-button-container'>
					<FontAwesomeIcon onClick={() => history.push(`/players/${params.id}`)} className="back-button" icon={faArrowLeft} />
				</div>
				<Col align="center" sm={12}>
				<h1>{(player.character !== null) ? player.character.character_name : 'Character'}</h1>
				</Col>
			</Row>
			<Row align="stretch" justify="center">
				<hr />
			</Row>
			{(player.character !== null) ? <><Row align="stretch" justify="center">
				<Col xs={4} style={{ textAlign: 'center' }}>
					<h4>{player.character.souls}</h4>
					<h5>Souls</h5>
				</Col>
				<Col xs={4} style={{ textAlign: 'center' }}>
					<h4>{player.character.soul_fragments}</h4>
					<h5>Souls Fragments</h5>
				</Col>
				<Col xs={4} style={{ textAlign: 'center' }}>
					<h4>{player.character.max_energy}</h4>
					<h5>Max Energy</h5>
				</Col>
			</Row>
			<Row align="stretch" justify="center" style={{ rowGap: '2em' }}>
				<Col xs={12} sm={6}>
					<h3>Class</h3>
					<PlayerCharacterInfo activeClass={activeClass} character={player.character} />
				</Col>
				<Col xs={12} sm={6}>
					<h3>Origin</h3>
					<PlayerOrigin origin={player.character.character_origin} />
				</Col>
			</Row></> : <p>No universe character.</p>}
		</Container>
    </>
  );
};

export default Character;
