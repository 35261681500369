import React, { useEffect, useState } from 'react';
import {
  Route, Switch, useLocation, withRouter,
} from 'react-router-dom';
import {
  Container, Row, Col,
} from 'shards-react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Hidden, Visible } from 'react-grid-system';
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import Login from './pages/Login/Login';
import Error from './pages/Error/Error';
import Player from './pages/Portal/Player/Player';
import Initial from './pages/Portal/Initial/Initial';
import TarvosNavbar from './components/Navbar/Navbar';
import MobileNavbar from './components/MobileNav/MobileNav';
import PlayerPicker from './pages/Portal/PlayerPicker/PlayerPicker';
import Players from './pages/Portal/Staff/Players/Players';
import AccountPrefs from './pages/Portal/AccountPrefs/AccountPrefs';
import Logs from './pages/Portal/Staff/Logs/Logs';
import PlayerView from './pages/Portal/Staff/PlayerView/PlayerView';
import ServerManager from './pages/Portal/Staff/ServerManager/ServerManager';
import Character from './pages/Portal/Character/Character';
import Clan from './pages/Portal/Clan/Clan';
import './App.css';
import Store from './pages/Portal/Store/Store';
import Checkout from './pages/Portal/Store/Checkout/Checkout';

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location.href = '/login';
  }
});

const App = () => {
  const [cookies] = useCookies(['tarvosportal']);
  const location = useLocation();
  const [staff, setStaff] = useState(false);
  const [superStaff, setSuperStaff] = useState(true);
  useEffect(() => {
    if (location.pathname !== '/login') {
      axios.get('https://api.tarvos-gaming.com/api/v4/public/me', {
        headers: {
          Authorization: `Bearer ${cookies.tarvosportal}`,
        },
      }).then((res) => {
        setStaff(res.data.staff);
        setSuperStaff(res.data.super_staff);
      });
    }
  }, []);

  const NavbarMemo = React.memo(TarvosNavbar);
  const MobileNavbarMemo = React.memo(MobileNavbar);

  return (
    <PayPalScriptProvider options={{ "client-id": "AY1FvM0dr5x-sX2E39TD0JYeIPW3zKhoyvkp0WJXo4XB4M8qlUC3Lmo_JsJe0yd0BYVXsqfpoW2QxkXr" }}>
      <main className="main">
        <Container className="main-container noPadding" fluid>
          <Hidden xs>
            <Row className="noMargin">
              <Col className="noPadding">
                {(location.pathname !== '/login' && location.pathname !== '/players' && location.pathname !== '/') && <NavbarMemo superStaff={superStaff} staff={staff} />}
              </Col>
            </Row>
          </Hidden>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/players/:id/character" component={Character} />
            <Route path="/players/:id/clan" component={Clan} />
            <Route path="/players/:id" component={Player} />
            <Route path="/players" component={PlayerPicker} />
            <Route path="/prefs" component={AccountPrefs} />
            <Route path="/staff/servers" render={(props) => <ServerManager {...props} superStaff={superStaff} />} />
            <Route path="/staff/players/:id" render={(props) => <PlayerView {...props} superStaff={superStaff} />} />
            <Route path="/staff/players" component={Players} />
            <Route path="/staff/logs" component={Logs} />
            <Route path="/store/:item" component={Checkout} />
            <Route path="/store" component={Store} />
            <Route path="/" component={Initial} />
            <Route component={Error} />
          </Switch>
          <Visible xs>
            <Row
              style={{
                position: 'absolute', bottom: 0, width: '100%', backgroundColor: '#17191f',
              }}
              className="noMargin"
            >
              <Col style={{ display: 'flex', justifyContent: 'center' }} className="noPadding">
                {(location.pathname !== '/login' && location.pathname !== '/players' && location.pathname !== '/') && <MobileNavbarMemo superStaff={superStaff} staff={staff} />}
              </Col>
            </Row>
          </Visible>
        </Container>
      </main>
    </PayPalScriptProvider>
  );
};

export default withRouter(App);
