import React from 'react';
import './Login.css';
import {
  Card,
  CardBody,
  Button,
} from 'shards-react';
import logo from '../../tarvoslogo.png';
import bg from '../../bg.jpg';

const Login = () => (
  <div
    className="app"
    style={{
      backgroundImage: `url(${bg})`,
    }}
  >
    <Card className="dark">
      <CardBody className="logincardbody">
        <img alt="tarvos logo" className="logincardimage" src={logo} />
        <Button
          block
          onClick={() => {
            window.location.href = 'https://tarvos-gaming.com/forums/oauth/authorize?client_id=947830d4a0cf7a75aece182546548e1d&response_type=code&scope=profile';
            }}
        >Login</Button>
      </CardBody>
    </Card>
  </div>
);

export default Login;
